import React, { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { HelpCircle, MessageSquare, MoreVertical } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

const HelpButton = () => {
  const [showChat, setShowChat] = useState(false);
  const [isCrispLoaded, setIsCrispLoaded] = useState(false);

  const loadCrispChat = () => {
    if (!isCrispLoaded) {
      window.$crisp = [];
      window.CRISP_WEBSITE_ID = "8598795d-58e2-462d-a6ae-a6fa8fe08ef7"; // Replace with your actual Crisp website ID
      const d = document;
      const s = d.createElement("script");
      s.src = "https://client.crisp.chat/l.js";
      s.async = 1;
      d.getElementsByTagName("head")[0].appendChild(s);
      setIsCrispLoaded(true);
    }
  };

  const toggleChat = () => {
    if (!showChat) {
      loadCrispChat();
    }
    setShowChat(!showChat);
  };

  useEffect(() => {
    if (showChat && isCrispLoaded) {
      window.$crisp.push(["do", "chat:open"]);
    }
  }, [showChat, isCrispLoaded]);

  return (
    <div className="fixed bottom-8 right-8 flex flex-col items-end space-y-4">
      <AnimatePresence>
        {showChat && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
          ></motion.div>
        )}
      </AnimatePresence>
      <Button
        onClick={toggleChat}
        className="rounded-full w-14 h-14 bg-gray-800 hover:bg-gray-700 transition-colors duration-200"
      >
        {showChat ? (
          <MoreVertical className="h-8 w-8 text-white" />
        ) : (
          <HelpCircle className="h-8 w-8 text-white" />
        )}
      </Button>
    </div>
  );
};

export default HelpButton;
