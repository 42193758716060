import React from "react";
import { Label } from "../ui/label";
import { Input } from "../ui/input";

const ColorPicker = ({ color, onChange, label }) => {
  return (
    <div className="flex flex-col items-start">
      <Label className="mb-1">{label}</Label>
      <div className="flex items-center space-x-2">
        <div
          className="w-8 h-8 rounded-full border border-gray-300 cursor-pointer"
          style={{ backgroundColor: color }}
          onClick={() => document.getElementById(`color-${label}`).click()}
        />
        <Input
          id={`color-${label}`}
          type="color"
          value={color}
          onChange={(e) => onChange(e.target.value)}
          className="w-12 h-8 p-0 border-0"
        />
        <Input
          type="text"
          value={color}
          onChange={(e) => onChange(e.target.value)}
          className="w-24"
        />
      </div>
    </div>
  );
};

export default ColorPicker;
