import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Toaster } from "./components/ui/toaster";
import PreviewPage from "./components/PreviewPage";

import { AuthProvider, useAuth } from "./contexts/AuthContext";
import { GlobalProvider } from "./contexts/GlobalContext";
import Navbar from "./components/Navbar";
import WebsiteBuilder from "./components/WebsiteBuilder";
import Login from "./components/Login";
import Signup from "./components/Signup";
import SavedPages from "./components/SavedPages";
import Profile from "./components/Profile";
import EditPage from "./components/EditPage";
import { ToastProvider } from "./components/ui/toast";
import { TooltipProvider } from "./components/ui/tooltip";
import NewWebsite from "./components/NewWebsite";

const PrivateRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const location = useLocation();

  if (loading) return <div>Loading...</div>;
  return user ? (
    children
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

const PublicRoute = ({ children }) => {
  const { user, loading } = useAuth();
  if (loading) return <div>Loading...</div>;
  return user ? <Navigate to="/" /> : children;
};

function AppContent() {
  const { user } = useAuth();
  const location = useLocation();

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100">
      {!location.pathname.startsWith("/preview") && <Navbar />}

      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/signup"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        <Route
          path="/new-website"
          element={
            <PrivateRoute>
              <NewWebsite />
            </PrivateRoute>
          }
        />
        <Route
          path="/saved-pages"
          element={
            <PrivateRoute>
              <SavedPages />
            </PrivateRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <PrivateRoute>
              <Profile />
            </PrivateRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <NewWebsite />
            </PrivateRoute>
          }
        />
        <Route path="/preview/:pageId" element={<PreviewPage />} />

        <Route
          path="/edit/:pageId"
          element={
            <PrivateRoute>
              <EditPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/new-project"
          element={
            <PrivateRoute>
              <NewWebsite />
            </PrivateRoute>
          }
        />
      </Routes>
    </div>
  );
}

function App() {
  return (
    <AuthProvider>
      <GlobalProvider>
        <TooltipProvider>
          <ToastProvider>
            <Router>
              <AppContent />
              <Toaster />
            </Router>
          </ToastProvider>
        </TooltipProvider>
      </GlobalProvider>
    </AuthProvider>
  );
}

export default App;
