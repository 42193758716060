import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useGlobal } from "../contexts/GlobalContext";
import { useToast } from "./ui/use-toast";
import StyleSettings from "./StyleSettings";
import WebsiteInitializer from "./WebsiteInitializer";
import { motion } from "framer-motion";
import { Globe, Layout, ChevronRight } from "lucide-react";

const ProjectTypeSelector = ({ onSelect }) => {
  return (
    <div className="flex justify-center items-center min-h-screen ">
      <div className="w-full max-w-5xl">
        <motion.h1
          className="text-5xl md:text-6xl font-extrabold text-center text-white mb-6"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
        >
          Choose Your Project Type
        </motion.h1>
        <motion.p
          className="text-xl text-center text-gray-400 mb-12 max-w-2xl mx-auto"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          Select the type of project you want to create with UiMagic and start
          building your digital vision
        </motion.p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <ProjectCard
            icon={<Globe />}
            title="Create a Website"
            description="Build a multi-page website with various sections and full customization options."
            onClick={() => onSelect("website")}
            gradient="from-blue-600 to-indigo-600"
          />
          <ProjectCard
            icon={<Layout />}
            title="Create a Web App"
            description="Develop a single-page interactive web application with focused functionality and seamless user experience."
            onClick={() => onSelect("app")}
            gradient="from-green-600 to-teal-600"
          />
        </div>
      </div>
    </div>
  );
};

const ProjectCard = ({ icon, title, description, onClick, gradient }) => (
  <motion.div
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <div
      className="relative overflow-hidden rounded-2xl cursor-pointer group"
      onClick={onClick}
    >
      <div
        className={`absolute inset-0 bg-gradient-to-br ${gradient} opacity-80 group-hover:opacity-100 transition-opacity duration-300`}
      />
      <div className="relative p-8 flex flex-col h-full backdrop-blur-sm">
        <div className="flex items-center mb-6">
          <div className="p-3 rounded-full bg-white bg-opacity-20 mr-4">
            {React.cloneElement(icon, { className: "w-8 h-8 text-white" })}
          </div>
          <h3 className="text-2xl font-bold text-white">{title}</h3>
        </div>
        <p className="text-gray-200 mb-8 flex-grow">{description}</p>
        <div className="flex items-center justify-between text-white font-semibold">
          <span>Get Started</span>
          <ChevronRight className="w-6 h-6 transform group-hover:translate-x-1 transition-transform duration-300" />
        </div>
      </div>
    </div>
  </motion.div>
);

const NewWebsite = () => {
  const [step, setStep] = useState(0);
  const [projectType, setProjectType] = useState(null);
  const { user } = useAuth();
  const {
    websiteContext,
    setWebsiteContext,
    globalStyle,
    setGlobalStyle,
    credits,
    setCredits,
    addNotification,
    setLoading,
  } = useGlobal();
  const navigate = useNavigate();
  const [isGenerating, setIsGenerating] = useState(false);
  const { toast } = useToast();

  const handleProjectTypeSelect = (type) => {
    setProjectType(type);
    setStep(1);
  };

  const handleWebsiteInitialize = async (selectedSections) => {
    const requiredCredits = selectedSections.length * 5; // Assuming 5 credits per section

    if (credits < requiredCredits) {
      toast({
        title: "Insufficient Credits",
        description: `You need ${requiredCredits} credits to generate this website. You currently have ${credits} credits. Please purchase more credits to continue.`,
        variant: "destructive",
      });
      return;
    }

    setIsGenerating(true);
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/generate-full-website`,
        {
          userId: user.id,
          selectedSections,
          globalStyle,
          websiteContext,
        },
        { headers: { "User-ID": user.id } }
      );

      const pageId = response.data.pageId;
      setCredits(response.data.remainingCredits);
      addNotification({
        type: "success",
        message: "Website generated successfully!",
      });
      navigate(`/edit/${pageId}`);
    } catch (error) {
      console.error("Error generating website:", error);
      addNotification({
        type: "error",
        message: "Failed to generate website. Please try again.",
      });
    } finally {
      setIsGenerating(false);
      setLoading(false);
    }
  };

  const handleStyleSet = async (style) => {
    setGlobalStyle(style);
    setWebsiteContext(style.websiteContext);

    if (projectType === "website") {
      setStep(2);
    } else {
      // Generate app immediately after style selection
      await handleGenerateApp(style);
    }
  };

  const handleGenerateApp = async (style) => {
    setIsGenerating(true);
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/generate-app`,
        {
          userId: user.id,
          prompt: style.websiteContext, // Using websiteContext as the app description
          globalStyle: style,
        },
        { headers: { "User-ID": user.id } }
      );

      const pageId = response.data.pageId;
      setCredits(response.data.remainingCredits);
      addNotification({
        type: "success",
        message: "App generated successfully!",
      });
      navigate(`/edit/${pageId}`);
    } catch (error) {
      console.error("Error generating app:", error);
      addNotification({
        type: "error",
        message: "Failed to generate app. Please try again.",
      });
    } finally {
      setIsGenerating(false);
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <ProjectTypeSelector onSelect={handleProjectTypeSelect} />;
      case 1:
        return (
          <StyleSettings
            onStyleSet={handleStyleSet}
            initialContext={websiteContext}
            projectType={projectType}
          />
        );
      case 2:
        return projectType === "website" ? (
          <WebsiteInitializer
            websiteContext={websiteContext}
            onGenerateFullWebsite={handleWebsiteInitialize}
            isGenerating={isGenerating}
            globalStyle={globalStyle}
            availableCredits={credits}
          />
        ) : null; // We don't need this case for app generation anymore
      default:
        return null;
    }
  };

  return <div className="container mx-auto px-4 py-8">{renderStep()}</div>;
};

export default NewWebsite;
