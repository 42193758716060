import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "./ui/button";
import {
  Settings,
  LogOut,
  CreditCard,
  User,
  PlusCircle,
  FileText,
  ChevronDown,
  Menu,
  X,
} from "lucide-react";
import { useAuth } from "../contexts/AuthContext";
import { useGlobal } from "../contexts/GlobalContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from "./ui/dropdown-menu";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

const Navbar = () => {
  const { user, signOut } = useAuth();
  const { credits, fetchCredits } = useGlobal();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (user) {
      fetchCredits();
    }
  }, [user, fetchCredits]);

  const handleLogout = async () => {
    await signOut();
    navigate("/login");
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className="sticky top-0 z-50 w-full bg-gradient-to-r from-gray-900/70 to-gray-800/70 backdrop-blur-md shadow-lg">
      <div className="container mx-auto px-4 sm:px-6 py-4 flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <img
            src="https://cloud-1de12d.b-cdn.net/media/original/456c9969bc5d24f906f9335a93bc7810/UiMagicLogo.svg"
            alt="UiMagic Logo"
            className="h-8 sm:h-10 transition-all duration-300 hover:scale-105 cursor-pointer"
            onClick={() => navigate("/new-website")}
          />
          <div className="hidden md:flex items-center space-x-4">
            {user && <ProjectsMenu navigate={navigate} />}
          </div>
        </div>

        <div className="hidden md:flex items-center space-x-2">
          {user && (
            <>
              <CreditsMenu credits={credits} navigate={navigate} />
              <UserMenu
                user={user}
                handleLogout={handleLogout}
                navigate={navigate}
              />
            </>
          )}
          {!user && <AuthButtons navigate={navigate} />}
        </div>

        <div className="md:hidden">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            aria-label="Toggle menu"
          >
            {isMobileMenuOpen ? (
              <X className="h-6 w-6 text-gray-200" />
            ) : (
              <Menu className="h-6 w-6 text-gray-200" />
            )}
          </Button>
        </div>
      </div>

      <AnimatePresence>
        {isMobileMenuOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "auto" }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden bg-gray-800"
          >
            <div className="px-4 py-2 space-y-2">
              {user && (
                <>
                  <MobileProjectsMenu
                    navigate={navigate}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                  />
                  <MobileCreditsMenu
                    credits={credits}
                    navigate={navigate}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                  />
                  <MobileUserMenu
                    user={user}
                    handleLogout={handleLogout}
                    navigate={navigate}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                  />
                </>
              )}
              {!user && (
                <MobileAuthButtons
                  navigate={navigate}
                  setIsMobileMenuOpen={setIsMobileMenuOpen}
                />
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

const ProjectsMenu = ({ navigate }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="text-gray-200 hover:text-white transition-colors duration-200"
        >
          Projects
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem onClick={() => navigate("/saved-pages")}>
          <FileText className="mr-2 h-4 w-4" />
          Saved Pages
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate("/new-website")}>
          <PlusCircle className="mr-2 h-4 w-4" />
          New Project
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const UserMenu = ({ user, handleLogout, navigate }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="text-gray-400 hover:text-blue-400 hover:bg-gray-700/50 bg-gray-800/80 rounded-full transition-colors duration-200"
        >
          <User className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <div className="flex flex-col space-y-2 p-2">
          <p className="text-sm font-medium text-gray-900">Signed in as</p>
          <p className="text-xs text-gray-500 dark:text-gray-400 truncate">
            {user?.email}
          </p>
          <DropdownMenuSeparator />
          <DropdownMenuItem onClick={() => navigate("/profile")}>
            <Settings className="mr-2 h-4 w-4" />
            Profile
          </DropdownMenuItem>
          <DropdownMenuItem onClick={handleLogout}>
            <LogOut className="mr-2 h-4 w-4" />
            Log out
          </DropdownMenuItem>
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const AuthButtons = ({ navigate }) => (
  <>
    <Button
      variant="ghost"
      onClick={() => navigate("/login")}
      className="text-gray-200 hover:text-white transition-colors duration-200"
    >
      Log in
    </Button>
    <Button
      onClick={() => navigate("/signup")}
      className="bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-200"
    >
      Sign up
    </Button>
  </>
);

const CreditsMenu = ({ credits, navigate }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="text-gray-400 hover:text-blue-400 hover:bg-gray-700/50 bg-gray-800/80 rounded-full transition-colors duration-200 relative"
            onClick={() => navigate("/profile")}
          >
            <CreditCard className="h-5 w-5" />
            <span className="absolute -top-1 -right-1 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-500 rounded-full">
              {credits}
            </span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>You have {credits} credits</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

const MobileProjectsMenu = ({ navigate, setIsMobileMenuOpen }) => (
  <>
    <Button
      variant="ghost"
      className="w-full justify-start text-gray-200 hover:text-white transition-colors duration-200"
      onClick={() => {
        navigate("/saved-pages");
        setIsMobileMenuOpen(false);
      }}
    >
      <FileText className="mr-2 h-4 w-4" />
      Saved Pages
    </Button>
    <Button
      variant="ghost"
      className="w-full justify-start text-gray-200 hover:text-white transition-colors duration-200"
      onClick={() => {
        navigate("/new-website");
        setIsMobileMenuOpen(false);
      }}
    >
      <PlusCircle className="mr-2 h-4 w-4" />
      New Project
    </Button>
  </>
);

const MobileCreditsMenu = ({ credits, navigate, setIsMobileMenuOpen }) => (
  <Button
    variant="ghost"
    className="w-full justify-start text-gray-200 hover:text-white transition-colors duration-200"
    onClick={() => {
      navigate("/profile");
      setIsMobileMenuOpen(false);
    }}
  >
    <CreditCard className="mr-2 h-4 w-4" />
    {credits} Credits
  </Button>
);

const MobileUserMenu = ({
  user,
  handleLogout,
  navigate,
  setIsMobileMenuOpen,
}) => (
  <>
    <Button
      variant="ghost"
      className="w-full justify-start text-gray-200 hover:text-white transition-colors duration-200"
      onClick={() => {
        navigate("/profile");
        setIsMobileMenuOpen(false);
      }}
    >
      <Settings className="mr-2 h-4 w-4" />
      Profile
    </Button>
    <Button
      variant="ghost"
      className="w-full justify-start text-gray-200 hover:text-white transition-colors duration-200"
      onClick={() => {
        handleLogout();
        setIsMobileMenuOpen(false);
      }}
    >
      <LogOut className="mr-2 h-4 w-4" />
      Log out
    </Button>
  </>
);

const MobileAuthButtons = ({ navigate, setIsMobileMenuOpen }) => (
  <>
    <Button
      variant="ghost"
      className="w-full justify-start text-gray-200 hover:text-white transition-colors duration-200"
      onClick={() => {
        navigate("/login");
        setIsMobileMenuOpen(false);
      }}
    >
      Log in
    </Button>
    <Button
      className="w-full bg-blue-600 hover:bg-blue-700 text-white transition-colors duration-200"
      onClick={() => {
        navigate("/signup");
        setIsMobileMenuOpen(false);
      }}
    >
      Sign up
    </Button>
  </>
);

export default Navbar;
