import React, {
  createContext,
  useContext,
  useReducer,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";

// Initial state
const initialState = {
  globalStyle: {},
  websiteContext: "",
  credits: 0,
  isLoading: false,
  error: null,
  notifications: [],
};

// Action types
const SET_GLOBAL_STYLE = "SET_GLOBAL_STYLE";
const SET_WEBSITE_CONTEXT = "SET_WEBSITE_CONTEXT";
const SET_CREDITS = "SET_CREDITS";
const SET_LOADING = "SET_LOADING";
const SET_ERROR = "SET_ERROR";
const ADD_NOTIFICATION = "ADD_NOTIFICATION";
const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";

// Reducer
const globalReducer = (state, action) => {
  switch (action.type) {
    case SET_GLOBAL_STYLE:
      return { ...state, globalStyle: action.payload };
    case SET_WEBSITE_CONTEXT:
      return { ...state, websiteContext: action.payload };
    case SET_CREDITS:
      return { ...state, credits: action.payload };
    case SET_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    case ADD_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case REMOVE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (n) => n.id !== action.payload
        ),
      };
    default:
      return state;
  }
};

// Create context
const GlobalContext = createContext();

// Context provider component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(globalReducer, initialState);

  // Action creators
  const setGlobalStyle = useCallback((style) => {
    dispatch({ type: SET_GLOBAL_STYLE, payload: style });
  }, []);

  const setWebsiteContext = useCallback((context) => {
    dispatch({ type: SET_WEBSITE_CONTEXT, payload: context });
  }, []);

  const setCredits = useCallback((credits) => {
    dispatch({ type: SET_CREDITS, payload: credits });
  }, []);

  const setLoading = useCallback((isLoading) => {
    dispatch({ type: SET_LOADING, payload: isLoading });
  }, []);

  const setError = useCallback((error) => {
    dispatch({ type: SET_ERROR, payload: error });
  }, []);

  const addNotification = useCallback((notification) => {
    const id = Date.now();
    dispatch({ type: ADD_NOTIFICATION, payload: { ...notification, id } });
    setTimeout(() => removeNotification(id), 5000); // Auto-remove after 5 seconds
  }, []);

  const removeNotification = useCallback((id) => {
    dispatch({ type: REMOVE_NOTIFICATION, payload: id });
  }, []);

  // Utility functions
  const fetchCredits = useCallback(async () => {
    setLoading(true);
    try {
      const userId = localStorage.getItem("userId"); // Assume we store userId in localStorage after login
      if (!userId) {
        console.log("No user ID found, skipping credit fetch");
        return;
      }
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER_URL}/user-credits`,
        { headers: { "User-ID": userId } }
      );
      setCredits(response.data.credits);
      console.log("Credits fetched:", response.data.credits);
    } catch (error) {
      console.error("Error fetching credits:", error);
      setError("Failed to fetch credits. Please try again.");
      addNotification({ type: "error", message: "Failed to fetch credits" });
    } finally {
      setLoading(false);
    }
  }, [setLoading, setCredits, setError, addNotification]);

  const updateCredits = useCallback(
    async (userId, amount) => {
      setLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/update-credits`,
          { amount },
          { headers: { "User-ID": userId } }
        );
        setCredits(response.data.credits);
        addNotification({
          type: "success",
          message: `Credits updated: ${response.data.credits}`,
        });
      } catch (error) {
        console.error("Error updating credits:", error);
        setError("Failed to update credits. Please try again.");
        addNotification({ type: "error", message: "Failed to update credits" });
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setCredits, setError, addNotification]
  );

  // Effect to fetch initial data
  useEffect(() => {
    fetchCredits();
  }, [fetchCredits]);

  const value = {
    ...state,
    setGlobalStyle,
    setWebsiteContext,
    setCredits,
    setLoading,
    setError,
    addNotification,
    removeNotification,
    fetchCredits,
    updateCredits,
  };

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

// Custom hook to use the global context
export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
};
