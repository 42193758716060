import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../contexts/AuthContext";
import { useGlobal } from "../contexts/GlobalContext";
import WebsiteBuilder from "./WebsiteBuilder";
import AppEditor from "./AppEditor";
import ToolbarComponent from "./ToolbarComponent";
import { Loader2 } from "lucide-react";

const EditPage = () => {
  const [pageData, setPageData] = useState(null);
  const [isStyleDialogOpen, setIsStyleDialogOpen] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const [error, setError] = useState(null);
  const { pageId } = useParams();
  const { user } = useAuth();
  const {
    setGlobalStyle,
    setWebsiteContext,
    addNotification,
    setLoading,
    credits,
    setCredits,
  } = useGlobal();
  const navigate = useNavigate();

  const fetchPageData = useCallback(async () => {
    console.log("Fetching page data...");
    console.log("User ID:", user?.id);
    console.log("Page ID:", pageId);

    if (!user?.id || !pageId) {
      console.error("Missing user ID or page ID");
      setError("Missing user ID or page ID");
      return;
    }

    setLoading(true);
    try {
      const apiUrl = `${process.env.REACT_APP_API_SERVER_URL}/page/${pageId}`;
      console.log("API URL:", apiUrl);

      const response = await axios.get(apiUrl, {
        headers: { "User-ID": user.id },
      });

      console.log("API Response:", response.data);

      if (!response.data || !response.data.page) {
        throw new Error("Invalid response format");
      }

      setPageData(response.data.page);
      setGlobalStyle(response.data.page.global_style);
      setWebsiteContext(response.data.page.title);
      console.log("Page data set successfully");
    } catch (error) {
      console.error("Error fetching page data:", error);
      setError(error.message || "Failed to load page data. Please try again.");
      addNotification({
        type: "error",
        message: "Failed to load page data. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }, [
    pageId,
    user,
    setLoading,
    setGlobalStyle,
    setWebsiteContext,
    addNotification,
  ]);

  useEffect(() => {
    console.log("EditPage mounted");
    fetchPageData();
  }, [fetchPageData]);

  const handleSave = useCallback(
    async (data) => {
      setLoading(true);
      try {
        await axios.put(
          `${process.env.REACT_APP_API_SERVER_URL}/page/${pageId}`,
          {
            userId: user.id,
            title: data.title || pageData?.title,
            description: data.description || pageData?.description,
            sections: data.sections,
            global_style: data.globalStyle,
          }
        );
        addNotification({
          type: "success",
          message: "Project saved successfully!",
        });
      } catch (error) {
        console.error("Error saving page data:", error);
        addNotification({
          type: "error",
          message: "Failed to save project. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    },
    [user, pageId, pageData, setLoading, addNotification]
  );

  const handleImproveSection = useCallback(
    async (sectionId, improvementPrompt) => {
      if (credits < 3) {
        addNotification({
          type: "error",
          message: "Insufficient credits. Please add more credits to continue.",
        });
        return null;
      }

      setLoading(true);
      try {
        // Find the current section HTML
        const currentSection = pageData.sections.find(
          (section) => section.id === sectionId
        );
        if (!currentSection) {
          throw new Error("Section not found");
        }

        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/improve-section`,
          {
            pageId,
            sectionId,
            improvementPrompt,
            previousHtml: currentSection.html, // Include the previous HTML
          },
          {
            headers: { "User-ID": user.id },
          }
        );

        setPageData((prevData) => ({
          ...prevData,
          sections: prevData.sections.map((section) =>
            section.id === sectionId
              ? { ...section, html: response.data.html }
              : section
          ),
        }));

        setCredits(response.data.remainingCredits);

        addNotification({
          type: "success",
          message: "Section improved successfully!",
        });

        return response.data.html;
      } catch (error) {
        console.error("Error improving section:", error);
        addNotification({
          type: "error",
          message: "Failed to improve section. Please try again.",
        });
        return null;
      } finally {
        setLoading(false);
      }
    },
    [pageId, user, credits, setCredits, setLoading, addNotification, pageData]
  );

  const openStyleDialog = useCallback(() => setIsStyleDialogOpen(true), []);

  const exportHTML = useCallback(() => {
    // Implement HTML export functionality
    console.log("Export HTML functionality not implemented yet");
  }, []);

  const openPreview = useCallback(() => {
    window.open(`/preview/${pageId}`, "_blank");
  }, [pageId]);

  if (error) {
    return (
      <div className="text-center mt-8 text-red-500">
        <h2 className="text-xl font-bold mb-2">Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  if (!pageData) {
    return (
      <div className="flex flex-col justify-center items-center h-screen">
        <Loader2 className="h-12 w-12 animate-spin mb-4" />
        <span className="text-lg">Loading...</span>
        <p className="text-sm text-gray-500 mt-2">
          Please wait while we fetch your page data.
        </p>
      </div>
    );
  }

  console.log("Rendering EditPage content");

  return (
    <div
      className={`flex flex-col relative ${
        pageData.project_type === "app" ? "h-screen" : "h-full"
      }`}
    >
      {pageData.project_type === "app" ? (
        <>
          <AppEditor
            pageData={pageData}
            onSave={handleSave}
            onImproveSection={handleImproveSection}
          />

          <ToolbarComponent
            showMoreActions={showMoreActions}
            setShowMoreActions={setShowMoreActions}
            openStyleDialog={openStyleDialog}
            exportHTML={exportHTML}
            projectType={pageData.project_type}
            openPreview={openPreview}
          />
        </>
      ) : (
        <WebsiteBuilder
          initialData={{
            sections: pageData.sections,
            globalStyle: pageData.global_style,
            websiteContext: pageData.title,
            pageId: pageId,
            projectType: pageData.project_type || "website",
          }}
          onSave={handleSave}
          onImproveSection={handleImproveSection}
        />
      )}
    </div>
  );
};

export default EditPage;
