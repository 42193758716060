import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
} from "./ui/card";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Edit2, Save, Trash2, Plus, FileText, Layout } from "lucide-react";
import { motion } from "framer-motion";
import { useToast } from "./ui/use-toast";

const SavedPages = () => {
  const [savedPages, setSavedPages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingId, setEditingId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");
  const { user } = useAuth();
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    fetchSavedPages();
  }, [user]);

  const fetchSavedPages = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_SERVER_URL}/saved-pages`,
        {
          headers: { "User-ID": user?.id },
        }
      );
      setSavedPages(response.data.pages);
    } catch (error) {
      console.error("Error fetching saved pages:", error);
      toast({
        title: "Error",
        description: "Failed to fetch saved pages. Please try again.",
        variant: "destructive",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditPage = (pageId) => {
    navigate(`/edit/${pageId}`);
  };

  const handleEditTitle = (pageId, currentTitle) => {
    setEditingId(pageId);
    setEditingTitle(currentTitle);
  };

  const handleSaveTitle = async (pageId) => {
    try {
      await axios.put(
        `${process.env.REACT_APP_API_SERVER_URL}/update-page-title`,
        {
          pageId,
          title: editingTitle,
          userId: user?.id,
        }
      );
      setEditingId(null);
      fetchSavedPages(); // Refresh the list
      toast({
        title: "Success",
        description: "Page title updated successfully.",
      });
    } catch (error) {
      console.error("Error updating page title:", error);
      toast({
        title: "Error",
        description: "Failed to update page title. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDeletePage = async (pageId) => {
    if (window.confirm("Are you sure you want to delete this project?")) {
      try {
        await axios.delete(
          `${process.env.REACT_APP_API_SERVER_URL}/delete-page/${pageId}`,
          {
            headers: { "User-ID": user?.id },
          }
        );
        setSavedPages((prevPages) =>
          prevPages.filter((page) => page.id !== pageId)
        );
        toast({
          title: "Success",
          description: "Project deleted successfully.",
        });
      } catch (error) {
        console.error("Error deleting project:", error);
        toast({
          title: "Error",
          description: "Failed to delete project. Please try again.",
          variant: "destructive",
        });
      }
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">Your Projects</h1>
        <Button
          onClick={() => navigate("/new-website")}
          className="bg-blue-500 hover:bg-blue-600"
        >
          <Plus className="mr-2 h-4 w-4" /> New Project
        </Button>
      </div>
      {savedPages.length === 0 ? (
        <div className="text-center py-20">
          <FileText className="mx-auto h-16 w-16 text-gray-400 mb-4" />
          <h2 className="text-2xl font-semibold mb-2">No projects yet</h2>
          <p className="text-gray-500 mb-4">
            Create your first project to get started
          </p>
          <Button
            onClick={() => navigate("/new-website")}
            className="bg-blue-500 hover:bg-blue-600"
          >
            <Plus className="mr-2 h-4 w-4" /> Create New Project
          </Button>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {savedPages.map((page) => (
            <motion.div
              key={page.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Card
                className="hover:shadow-lg transition-shadow duration-300 cursor-pointer"
                onClick={() => handleEditPage(page.id)}
              >
                <CardHeader className="relative">
                  <div className="flex items-center justify-between">
                    {editingId === page.id ? (
                      <div className="flex items-center w-full">
                        <Input
                          value={editingTitle}
                          onChange={(e) => setEditingTitle(e.target.value)}
                          className="mr-2 flex-grow"
                          onClick={(e) => e.stopPropagation()}
                        />
                        <Button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeletePage(page.id);
                          }}
                          size="sm"
                          variant="ghost"
                          className="text-red-500 hover:text-red-600"
                        >
                          <Trash2 className="h-4 w-4" />
                        </Button>
                      </div>
                    ) : (
                      <>
                        <CardTitle className="text-xl">
                          {page.title || "Untitled Project"}
                        </CardTitle>
                        <div className="flex space-x-2">
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleEditTitle(page.id, page.title);
                            }}
                            size="sm"
                            variant="ghost"
                          >
                            <Edit2 className="h-4 w-4" />
                          </Button>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeletePage(page.id);
                            }}
                            size="sm"
                            variant="ghost"
                            className="text-red-500 hover:text-red-600"
                          >
                            <Trash2 className="h-4 w-4" />
                          </Button>
                        </div>
                      </>
                    )}
                  </div>
                  <CardDescription className="text-sm text-gray-500 mt-1">
                    {new Date(page.created_at).toLocaleDateString()}
                  </CardDescription>
                </CardHeader>
                <CardContent>
                  <p className="text-gray-600 mb-4">
                    {page.description || "No description"}
                  </p>
                  <div className="flex items-center text-blue-500">
                    <Layout className="h-5 w-5 mr-2" />
                    <span>Open Project</span>
                  </div>
                </CardContent>
              </Card>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedPages;
