import React, { useState, useEffect } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Card, CardContent } from "./ui/card";
import {
  Wand2,
  RefreshCw,
  Check,
  X,
  ChevronUp,
  ChevronDown,
  Trash2,
  CopyPlus,
  Edit3,
  MoreVertical,
} from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./ui/dropdown-menu";
import { motion, AnimatePresence } from "framer-motion";

const SectionCard = ({
  section,
  index,
  onToggleAI,
  onToggleFullscreen,
  onDuplicate,
  onRemove,
  onGenerateSection,
  isEditing,
  onImproveSection,
  onAcceptSection,
  onUpdateDescription,
  onUpdateHtml,
  onElementEdit,
  loading,
  fullscreenSection,
  renderSection,
  renderSectionForExport,
  onMoveUp,
  onMoveDown,
  isFirstSection,
  isLastSection,
  projectType,
  onSaveSection,
}) => {
  const [improvementDescription, setImprovementDescription] = useState("");
  const [isImproving, setIsImproving] = useState(false);
  const [improvedHtml, setImprovedHtml] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [editingElement, setEditingElement] = useState(null);

  useEffect(() => {
    if (!improvedHtml) {
      setImprovedHtml(null);
    }
  }, [section?.html]);

  const handleImprove = async () => {
    setIsImproving(true);
    try {
      const newHtml = await onImproveSection(
        section.id,
        improvementDescription || "Generate a new section"
      );
      setImprovedHtml(newHtml);
    } catch (error) {
      console.error("Error improving section:", error);
    } finally {
      setIsImproving(false);
    }
  };

  const handleAccept = async () => {
    if (improvedHtml) {
      onUpdateHtml(section.id, improvedHtml);
      onAcceptSection(section.id, improvedHtml);
      if (onSaveSection) {
        await onSaveSection(section.id, improvedHtml);
      }
      setImprovedHtml(null);
      setImprovementDescription("");
    }
  };

  const handleDeny = () => {
    setImprovedHtml(null);
    setImprovementDescription("");
  };

  const displayHtml = improvedHtml || section.html || "";

  return (
    <Card
      className={`section-card overflow-hidden shadow-md relative bg-gray-800/95 backdrop-blur-sm ${
        isFirstSection
          ? "rounded-none rounded-t-lg"
          : isLastSection
          ? "rounded-none rounded-b-lg"
          : "rounded-none"
      }`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <CardContent className="p-0">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: isHovered ? 1 : 0 }}
          transition={{ duration: 0.3 }}
          className="absolute top-4 right-4 left-4 flex justify-between items-center z-10"
        >
          <div className="flex space-x-2">
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => onMoveUp(index)}
                  disabled={isFirstSection}
                  size="sm"
                  variant="ghost"
                  className="text-gray-300 hover:text-white hover:bg-gray-700"
                >
                  <ChevronUp className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Move section up</TooltipContent>
            </Tooltip>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  onClick={() => onMoveDown(index)}
                  disabled={isLastSection}
                  size="sm"
                  variant="ghost"
                  className="text-gray-300 hover:text-white hover:bg-gray-700"
                >
                  <ChevronDown className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>Move section down</TooltipContent>
            </Tooltip>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                size="sm"
                variant="ghost"
                className="text-gray-300 hover:text-white hover:bg-gray-700"
              >
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem onClick={() => onDuplicate(index)}>
                <CopyPlus className="mr-2 h-4 w-4" /> Duplicate
              </DropdownMenuItem>
              <DropdownMenuItem onClick={() => onRemove(section.id)}>
                <Trash2 className="mr-2 h-4 w-4" /> Remove
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </motion.div>

        <div className="w-full overflow-x-hidden min-h-[16rem]">
          {displayHtml ? (
            <div className="section-content">
              {renderSection({
                ...section,
                html: displayHtml,
              })}
              <div style={{ display: "none" }}>
                {renderSectionForExport({ ...section, html: displayHtml })}
              </div>
            </div>
          ) : (
            <div className="empty-section m-8 mb-12 w-full h-64 flex items-center justify-center">
              <p className="text-gray-400">
                This section is empty. Generate content to get started.
              </p>
            </div>
          )}
        </div>

        <motion.div
          className="absolute bottom-4 left-0 right-0 mx-auto z-10 w-11/12 max-w-2xl"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: isHovered ? 1 : 0, y: isHovered ? 0 : 20 }}
          transition={{ duration: 0.3 }}
        >
          <div className="bg-blue-900/80 backdrop-blur-sm rounded-lg shadow-lg p-3 flex items-center space-x-2">
            <Input
              value={improvementDescription}
              onChange={(e) => setImprovementDescription(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && !isImproving) {
                  e.preventDefault();
                  handleImprove();
                }
              }}
              placeholder={
                displayHtml
                  ? "Suggest improvements..."
                  : "Describe your ideal section..."
              }
              className="flex-grow bg-transparent text-white placeholder-gray-300 border-gray-600 focus:border-blue-400"
            />
            <Button
              onClick={handleImprove}
              disabled={isImproving}
              className="bg-blue-500 hover:bg-blue-600 text-white whitespace-nowrap"
            >
              {isImproving ? (
                <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
              ) : displayHtml ? (
                <Edit3 className="mr-2 h-4 w-4" />
              ) : (
                <Wand2 className="mr-2 h-4 w-4" />
              )}
              {isImproving
                ? "Processing..."
                : displayHtml
                ? "Improve"
                : "Generate"}
            </Button>
          </div>
        </motion.div>

        <AnimatePresence>
          {improvedHtml && (
            <motion.div
              className="absolute bottom-20 left-0 right-0 mx-auto z-20 w-11/12 max-w-2xl"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.3 }}
            >
              <div className="bg-gray-700/90 backdrop-blur-sm rounded-lg shadow-lg p-4">
                <div className="flex justify-end space-x-2">
                  <Button
                    onClick={handleAccept}
                    className="bg-green-500 hover:bg-green-600"
                  >
                    <Check className="mr-2 h-4 w-4" /> Accept
                  </Button>
                  <Button
                    onClick={handleDeny}
                    variant="outline"
                    className="border-gray-500 text-gray-300 hover:bg-gray-600"
                  >
                    <X className="mr-2 h-4 w-4" /> Reject
                  </Button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
    </Card>
  );
};

export default SectionCard;
