import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

const PreviewPage = () => {
  const [previewContent, setPreviewContent] = useState("");
  const { pageId } = useParams();

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_SERVER_URL}/page/${pageId}`
        );
        const { sections, global_style, project_type } = response.data.page;

        if (project_type === "app") {
          setPreviewContent(sections[0].html); // Assuming the app HTML is in the first section
        } else {
          const html = generateFullHTML(sections, global_style);
          setPreviewContent(html);
        }
      } catch (error) {
        console.error("Error fetching page data:", error);
        setPreviewContent("<h1>Error loading preview</h1>");
      }
    };

    if (pageId) {
      fetchPageData();
    }
  }, [pageId]);

  const generateFullHTML = (sections, globalStyle) => {
    const sectionHtml = sections.map((section) => section.html).join("");
    return `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <script src="https://cdn.tailwindcss.com"></script>
        <title>Website Preview</title>
        <style>
          body {
            margin: 0;
            padding: 0;
            font-family: ${globalStyle?.fonts?.primary || "Arial, sans-serif"};
            color: ${globalStyle?.colors?.text || "#000000"};
            background-color: ${globalStyle?.colors?.background || "#ffffff"};
          }
          * {
            box-sizing: border-box;
          }
          ${globalStyle?.customCSS || ""}
        </style>
      </head>
      <body>
        ${sectionHtml}
      </body>
      </html>
    `;
  };

  return (
    <iframe
      srcDoc={previewContent}
      style={{
        width: "100%",
        height: "100vh",
        border: "none",
      }}
      title="Website Preview"
    />
  );
};

export default PreviewPage;
