import React from "react";
import Section from "./Section";
import SectionCard from "./SectionCard";

const SectionRenderer = ({
  section,
  index,
  onToggleAI,
  onToggleFullscreen,
  onDuplicate,
  onRemove,
  onGenerateSection,
  onImproveSection,
  onAcceptSection,
  onUpdateDescription,
  onUpdateHtml,
  onElementEdit,
  loading,
  onContentEdit,
  fullscreenSection,
  onMoveUp,
  onMoveDown,
  isFirstSection,
  isLastSection,
  projectType,
  onSaveSection,
  globalStyle,
}) => {
  if (!section) {
    return null;
  }

  return (
    <SectionCard
      section={section}
      index={index}
      onToggleAI={onToggleAI}
      onToggleFullscreen={onToggleFullscreen}
      onDuplicate={onDuplicate}
      onRemove={onRemove}
      onGenerateSection={onGenerateSection}
      onImproveSection={onImproveSection}
      onAcceptSection={onAcceptSection}
      onUpdateDescription={onUpdateDescription}
      onUpdateHtml={onUpdateHtml}
      onElementEdit={onElementEdit}
      loading={loading}
      fullscreenSection={fullscreenSection}
      renderSection={(sectionProps) => (
        <Section
          {...sectionProps}
          onElementEdit={onElementEdit}
          onUpdateHtml={onUpdateHtml}
          globalStyle={globalStyle}
          onContentEdit={onContentEdit}
        />
      )}
      renderSectionForExport={(sectionProps) => (
        <div
          data-export-version
          dangerouslySetInnerHTML={{ __html: sectionProps.html || "" }}
          style={{
            fontFamily: globalStyle?.fonts?.primary || "Arial, sans-serif",
            color: globalStyle?.colors?.text || "#000000",
            backgroundColor: globalStyle?.colors?.background || "#ffffff",
          }}
        />
      )}
      onMoveUp={onMoveUp}
      onMoveDown={onMoveDown}
      isFirstSection={isFirstSection}
      isLastSection={isLastSection}
      projectType={projectType}
      onSaveSection={onSaveSection}
    />
  );
};

export default SectionRenderer;
