import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "./ui/button";
import { Checkbox } from "./ui/checkbox";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "./ui/card";
import { useToast } from "./ui/use-toast";
import { Plus, Loader2, GripVertical, Check, X, Edit2 } from "lucide-react";
import { useGlobal } from "../contexts/GlobalContext";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const WebsiteInitializer = ({
  websiteContext,
  globalStyle,
  onGenerateFullWebsite,
  isGenerating,
}) => {
  const [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [customSection, setCustomSection] = useState("");
  const [editingSectionId, setEditingSectionId] = useState(null);
  const [editingTitle, setEditingTitle] = useState("");
  const { toast } = useToast();
  const { credits } = useGlobal();
  const requiredCredits = sections.filter((s) => s.selected).length * 5;
  const hasEnoughCredits = credits >= requiredCredits;

  useEffect(() => {
    const fetchSuggestions = async () => {
      setIsLoading(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/suggest-sections`,
          { websiteContext },
          { headers: { "User-ID": localStorage.getItem("userId") } }
        );
        if (response.data && Array.isArray(response.data.sections)) {
          setSections(
            response.data.sections.map((section) => ({
              ...section,
              selected: true,
            }))
          );
        } else {
          throw new Error("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching section suggestions:", error);
        toast({
          title: "Error",
          description: "Failed to fetch custom sections. Please try again.",
          variant: "destructive",
        });
        setSections([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (websiteContext) {
      fetchSuggestions();
    }
  }, [websiteContext, toast]);

  const toggleSectionSelection = (id) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id
          ? { ...section, selected: !section.selected }
          : section
      )
    );
  };

  const addCustomSection = () => {
    if (customSection.trim()) {
      setSections([
        ...sections,
        {
          id: `custom-${Date.now()}`,
          title: customSection,
          selected: true,
        },
      ]);
      setCustomSection("");
    }
  };

  const handleGenerateWebsite = () => {
    if (!hasEnoughCredits) {
      toast({
        title: "Insufficient Credits",
        description: `You need ${requiredCredits} credits to generate this website. You currently have ${credits} credits. Please purchase more credits to continue.`,
        variant: "destructive",
      });
      return;
    }
    const selectedSections = sections.filter((section) => section.selected);
    onGenerateFullWebsite(selectedSections, globalStyle, websiteContext);
  };

  const startEditingSection = (id, title) => {
    setEditingSectionId(id);
    setEditingTitle(title);
  };

  const handleEditSection = (id) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === id ? { ...section, title: editingTitle } : section
      )
    );
    setEditingSectionId(null);
    setEditingTitle("");
  };

  const cancelEditingSection = () => {
    setEditingSectionId(null);
    setEditingTitle("");
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newSections = Array.from(sections);
    const [reorderedItem] = newSections.splice(result.source.index, 1);
    newSections.splice(result.destination.index, 0, reorderedItem);
    setSections(newSections);
  };

  if (isLoading) {
    return (
      <Card className="w-full max-w-2xl mx-auto">
        <CardContent className="flex justify-center items-center h-64">
          <Loader2 className="h-8 w-8 animate-spin" />
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Generate Your Website</CardTitle>
        <CardDescription>
          Customize sections for your {websiteContext} website
        </CardDescription>
      </CardHeader>
      <CardContent>
        {sections.length > 0 ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sections">
              {(provided) => (
                <ul
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-2 mb-4"
                >
                  {sections.map((section, index) => (
                    <Draggable
                      key={section.id}
                      draggableId={section.id}
                      index={index}
                    >
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="flex items-center space-x-2 bg-gray-100 p-2 rounded group"
                        >
                          <div {...provided.dragHandleProps}>
                            <GripVertical className="h-5 w-5 text-gray-400" />
                          </div>
                          <Checkbox
                            checked={section.selected}
                            onCheckedChange={() =>
                              toggleSectionSelection(section.id)
                            }
                            id={section.id}
                          />
                          {editingSectionId === section.id ? (
                            <div className="flex-1 flex items-center space-x-2">
                              <Input
                                value={editingTitle}
                                onChange={(e) =>
                                  setEditingTitle(e.target.value)
                                }
                                className="flex-1"
                                autoFocus
                              />
                              <Button
                                size="sm"
                                onClick={() => handleEditSection(section.id)}
                              >
                                <Check className="h-4 w-4" />
                              </Button>
                              <Button
                                size="sm"
                                variant="outline"
                                onClick={cancelEditingSection}
                              >
                                <X className="h-4 w-4" />
                              </Button>
                            </div>
                          ) : (
                            <div className="flex-1 flex items-center justify-between">
                              <Label
                                htmlFor={section.id}
                                className="cursor-pointer"
                              >
                                {section.title}
                              </Label>
                              <Button
                                size="sm"
                                variant="ghost"
                                onClick={() =>
                                  startEditingSection(section.id, section.title)
                                }
                                className="opacity-0 group-hover:opacity-100 transition-opacity"
                              >
                                <Edit2 className="h-4 w-4" />
                              </Button>
                            </div>
                          )}
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        ) : (
          <p className="text-center text-gray-500 my-4">
            No sections suggested. Try adding custom sections below.
          </p>
        )}
        <div className="flex items-center space-x-2">
          <Input
            value={customSection}
            onChange={(e) => setCustomSection(e.target.value)}
            placeholder="Add custom section"
          />
          <Button onClick={addCustomSection} size="sm">
            <Plus className="h-4 w-4" />
          </Button>
        </div>
      </CardContent>
      <CardFooter className="flex flex-col items-stretch space-y-4">
        <div className="text-sm text-gray-600">
          Required credits: {requiredCredits} | Available credits: {credits}
        </div>
        <Button
          onClick={handleGenerateWebsite}
          className="w-full"
          disabled={isGenerating || sections.length === 0 || !hasEnoughCredits}
        >
          {isGenerating ? "Generating..." : "Generate Full Website"}
        </Button>
        {!hasEnoughCredits && (
          <div className="text-sm text-red-500">
            You don't have enough credits to generate this website. Please
            purchase more credits to continue.
          </div>
        )}
      </CardFooter>
    </Card>
  );
};

export default WebsiteInitializer;
