import React, { useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
  CardDescription,
  CardFooter,
} from "./ui/card";
import { Textarea } from "./ui/textarea";
import { Label } from "./ui/label";
import { motion, AnimatePresence } from "framer-motion";
import ColorPicker from "./common/ColorPicker";
import { Plus, X } from "lucide-react";

const StyleTypes = [
  {
    id: "modern brutalism",
    name: "Modern Brtualism",
    imageUrl:
      "https://fiunynrjczwampwcisxp.supabase.co/storage/v1/object/public/uiMagic/modern-brutalism.jpg",
  },

  {
    id: "retro vintage style use real image as background",
    name: "Retro",
    imageUrl:
      "https://fiunynrjczwampwcisxp.supabase.co/storage/v1/object/public/uiMagic/retro.jpg",
  },
  {
    id: "custom",
    name: "Custom",
    imageUrl:
      "https://fiunynrjczwampwcisxp.supabase.co/storage/v1/object/public/uiMagic/custom.png",
  },
];

const initialColorPalettes = [
  { id: "blue", colors: ["#1E40AF", "#3B82F6", "#93C5FD"] },
  { id: "green", colors: ["#065F46", "#10B981", "#6EE7B7"] },
  { id: "purple", colors: ["#5B21B6", "#8B5CF6", "#C4B5FD"] },
  { id: "red", colors: ["#991B1B", "#EF4444", "#FCA5A5"] },
];

const StyleSettings = ({ onStyleSet, initialContext, projectType }) => {
  const [websiteDescription, setWebsiteDescription] = useState(
    initialContext || ""
  );
  const [styleType, setStyleType] = useState("");
  const [customStyle, setCustomStyle] = useState("");
  const [appDescription, setAppDescription] = useState("");
  const [selectedPalette, setSelectedPalette] = useState(null);
  const [colorPalettes, setColorPalettes] = useState(initialColorPalettes);
  const [isEditingPalette, setIsEditingPalette] = useState(false);
  const [editingColors, setEditingColors] = useState([
    "#FFFFFF",
    "#FFFFFF",
    "#FFFFFF",
  ]);
  const [isLoading, setIsLoading] = useState(false);

  const handleContinue = async () => {
    setIsLoading(true);
    try {
      await onStyleSet({
        websiteContext:
          projectType === "app" ? appDescription : websiteDescription,
        styleType: styleType === "custom" ? "custom" : styleType,
        customStyle: styleType === "custom" ? customStyle : "",
        colorPalette: selectedPalette,
      });
    } catch (error) {
      console.error("Error in handleContinue:", error);
    }
    // Note: We're not setting isLoading to false here to keep the loading state active
  };

  const handleColorChange = (index, color) => {
    const newColors = [...editingColors];
    newColors[index] = color;
    setEditingColors(newColors);
  };

  const handleSaveCustomPalette = () => {
    const newPalette = {
      id: `custom-${Date.now()}`,
      colors: editingColors,
    };
    setColorPalettes([...colorPalettes, newPalette]);
    setSelectedPalette(newPalette);
    setIsEditingPalette(false);
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>
          Design Your {projectType === "app" ? "App" : "Website"}
        </CardTitle>
        <CardDescription>
          {projectType === "app"
            ? "Describe your app and choose its style"
            : "Describe your website and choose its style"}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-6">
        <div>
          <Label htmlFor="description">
            {projectType === "app"
              ? "Describe your app"
              : "What is your website about?"}
          </Label>
          <Textarea
            id="description"
            value={projectType === "app" ? appDescription : websiteDescription}
            onChange={(e) =>
              projectType === "app"
                ? setAppDescription(e.target.value)
                : setWebsiteDescription(e.target.value)
            }
            placeholder={
              projectType === "app"
                ? "E.g., Create a task management app with the ability to add, edit, and delete tasks. Include a feature to set due dates and priorities for each task."
                : "e.g., A modern dentist office in Bratislava"
            }
            className="w-full mt-2"
            rows={4}
          />
        </div>

        <AnimatePresence>
          {(websiteDescription || appDescription) && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="space-y-4"
            >
              <Label>Choose a style type</Label>
              <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 mt-2">
                {StyleTypes.map((type) => (
                  <div
                    key={type.id}
                    className={`cursor-pointer relative rounded-lg overflow-hidden ${
                      styleType === type.id ? "ring-2 ring-blue-500" : ""
                    }`}
                    onClick={() => setStyleType(type.id)}
                  >
                    <img
                      src={type.imageUrl}
                      alt={type.name}
                      className="w-full h-32 object-cover"
                    />
                    <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2">
                      {type.name}
                    </div>
                    {styleType === type.id && (
                      <div className="absolute top-2 right-2 bg-blue-500 text-white rounded-full p-1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          )}

          {styleType === "custom" && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Label htmlFor="custom-style">Describe your custom style</Label>
              <Input
                id="custom-style"
                value={customStyle}
                onChange={(e) => setCustomStyle(e.target.value)}
                placeholder="e.g., Sleek and professional with a touch of warmth"
                className="mt-2"
              />
            </motion.div>
          )}

          {styleType && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <Label>Choose a color palette</Label>
              <div className="flex flex-wrap gap-4 mt-2 items-center">
                {colorPalettes.map((palette) => (
                  <div
                    key={palette.id}
                    className={`cursor-pointer ${
                      selectedPalette?.id === palette.id
                        ? "ring-2 ring-blue-500 rounded-lg"
                        : ""
                    }`}
                    onClick={() => setSelectedPalette(palette)}
                  >
                    <div className="w-20 h-10 rounded-lg overflow-hidden flex">
                      {palette.colors.map((color, index) => (
                        <div
                          key={index}
                          style={{ backgroundColor: color }}
                          className="flex-1"
                        />
                      ))}
                    </div>
                  </div>
                ))}
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => setIsEditingPalette(true)}
                  className="w-20 h-10"
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </div>
            </motion.div>
          )}

          {isEditingPalette && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="mt-4"
            >
              <Label>Create custom color palette</Label>
              <div className="flex space-x-4 mt-2">
                {editingColors.map((color, index) => (
                  <ColorPicker
                    key={index}
                    color={color}
                    onChange={(newColor) => handleColorChange(index, newColor)}
                    label={`Color ${index + 1}`}
                  />
                ))}
              </div>
              <div className="flex justify-end space-x-2 mt-2">
                <Button
                  variant="outline"
                  onClick={() => setIsEditingPalette(false)}
                >
                  <X className="h-4 w-4 mr-2" />
                  Cancel
                </Button>
                <Button onClick={handleSaveCustomPalette}>
                  <Plus className="h-4 w-4 mr-2" />
                  Add Custom Palette
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </CardContent>
      <CardFooter>
        <Button
          onClick={handleContinue}
          className="w-full"
          disabled={
            (!websiteDescription && !appDescription) ||
            !styleType ||
            !selectedPalette ||
            isLoading
          }
        >
          {isLoading ? (
            <>
              <span className="mr-2">
                {projectType === "app" ? "Generating App..." : "Loading..."}
              </span>
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </>
          ) : (
            "Continue"
          )}
        </Button>
      </CardFooter>
    </Card>
  );
};

export default StyleSettings;
