import React, { useState, useCallback, useEffect, useRef } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Wand2, RefreshCw, Check, X } from "lucide-react";
import axios from "axios";
import { useToast } from "./ui/use-toast";
import { motion, AnimatePresence } from "framer-motion";

const AppEditor = ({ pageData, onSave, globalStyle }) => {
  const [appHtml, setAppHtml] = useState(pageData.sections[0].html);
  const [improvementPrompt, setImprovementPrompt] = useState("");
  const [isImproving, setIsImproving] = useState(false);
  const [improvedHtml, setImprovedHtml] = useState(null);
  const iframeRef = useRef(null);
  const { toast } = useToast();

  useEffect(() => {
    setAppHtml(pageData.sections[0].html);
  }, [pageData]);

  useEffect(() => {
    if (iframeRef.current) {
      const doc = iframeRef.current.contentDocument;
      doc.open();
      doc.write(improvedHtml || appHtml);
      doc.close();
    }
  }, [appHtml, improvedHtml]);

  const handleImprove = useCallback(async () => {
    if (improvementPrompt.trim()) {
      setIsImproving(true);
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/improve-app`,
          {
            description: improvementPrompt,
            previousHtml: appHtml,
            globalStyle: globalStyle,
          },
          { headers: { "User-ID": localStorage.getItem("userId") } }
        );

        if (response.data.html) {
          setImprovedHtml(response.data.html);
          toast({
            title: "Improvement Ready",
            description: "Review and accept the changes or try again.",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error("Error improving app:", error);
        toast({
          title: "Error",
          description: "Failed to improve app. Please try again.",
          variant: "destructive",
        });
      } finally {
        setIsImproving(false);
      }
    }
  }, [improvementPrompt, appHtml, globalStyle, toast]);

  const handleAccept = useCallback(() => {
    setAppHtml(improvedHtml);
    onSave({
      ...pageData,
      sections: [{ ...pageData.sections[0], html: improvedHtml }],
    });
    setImprovedHtml(null);
    setImprovementPrompt("");
    toast({
      title: "Success",
      description: "Improvements applied successfully!",
      duration: 3000,
    });
  }, [improvedHtml, onSave, pageData, toast]);

  const handleReject = useCallback(() => {
    setImprovedHtml(null);
    setImprovementPrompt("");
    toast({
      title: "Rejected",
      description: "Improvements were discarded.",
      duration: 3000,
    });
  }, [toast]);

  return (
    <div className="relative h-full">
      <iframe
        ref={iframeRef}
        srcDoc={improvedHtml || appHtml}
        title="App Preview"
        className="w-full h-full border-none"
      />
      <div className="fixed bottom-4 left-0 right-0 mx-auto z-10 w-11/12 max-w-2xl">
        <AnimatePresence>
          {improvedHtml && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              className="bg-gray-800 p-4 rounded-lg shadow-lg mb-4"
            >
              <p className="text-white mb-4">
                Do you want to apply these improvements?
              </p>
              <div className="flex justify-end space-x-2">
                <Button
                  onClick={handleAccept}
                  className="bg-green-500 hover:bg-green-600"
                >
                  <Check className="mr-2 h-4 w-4" /> Accept
                </Button>
                <Button
                  onClick={handleReject}
                  variant="outline"
                  className="border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
                >
                  <X className="mr-2 h-4 w-4" /> Reject
                </Button>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <div className="bg-blue-900/80 backdrop-blur-sm rounded-lg shadow-lg p-3 flex items-center space-x-2">
          <Input
            value={improvementPrompt}
            onChange={(e) => setImprovementPrompt(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !isImproving) {
                e.preventDefault();
                handleImprove();
              }
            }}
            placeholder="Suggest improvements for your app..."
            className="flex-grow bg-transparent text-white placeholder-gray-300 border-gray-600 focus:border-blue-400"
          />
          <Button
            onClick={handleImprove}
            disabled={isImproving}
            className="bg-blue-500 hover:bg-blue-600 text-white whitespace-nowrap"
          >
            {isImproving ? (
              <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
            ) : (
              <Wand2 className="mr-2 h-4 w-4" />
            )}
            {isImproving ? "Improving..." : "Improve"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AppEditor;
