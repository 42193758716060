import React, { useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { useGlobal } from "../contexts/GlobalContext";
import { Button } from "./ui/button";
import { useToast } from "./ui/use-toast";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
import {
  Card,
  CardHeader,
  CardTitle,
  CardDescription,
  CardContent,
  CardFooter,
} from "./ui/card";
import { Badge } from "./ui/badge";
import { CreditCard, Zap, Star, TrendingUp, Loader } from "lucide-react";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const Profile = () => {
  const { user } = useAuth();
  const { credits, setCredits } = useGlobal();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const handleBuyCredits = async () => {
    setIsLoading(true);
    try {
      console.log("Initiating live Stripe checkout");

      const stripe = await stripePromise;
      const response = await axios.post(
        `${process.env.REACT_APP_API_SERVER_URL}/create-checkout-session`,
        {},
        {
          headers: { "User-ID": user?.id },
        }
      );
      const session = response.data;
      console.log("Received live session ID:", session.id);

      const result = await stripe.redirectToCheckout({
        sessionId: session.id,
      });

      if (result.error) {
        console.error("Stripe live redirect error:", result.error);
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error("Error initiating live Stripe checkout:", error);
      toast({
        title: "Error",
        description: "Failed to initiate checkout. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <Card className="max-w-2xl mx-auto">
        <CardHeader>
          <CardTitle className="text-2xl font-bold">
            Your UiMagic Profile
          </CardTitle>
          <CardDescription>
            Manage your credits and unlock the full potential of AI-powered web
            design
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div className="flex items-center justify-between bg-gray-100 p-4 rounded-lg">
            <div className="flex items-center space-x-2">
              <CreditCard className="h-6 w-6 text-blue-600" />
              <span className="text-lg font-semibold">Current Credits:</span>
            </div>
            <Badge variant="secondary" className="text-lg px-3 py-1">
              {credits}
            </Badge>
          </div>

          <div className="space-y-4">
            <h3 className="text-xl font-semibold">Credit Usage Guide</h3>
            <ul className="list-disc pl-5 space-y-2">
              <li>
                <strong>5 credits</strong> per section generation
              </li>
              <li>
                <strong>3 credits</strong> to improve an existing section
              </li>
              <li>
                <strong>1 credit</strong> for minor adjustments
              </li>
            </ul>
          </div>

          <div className="bg-blue-50 p-4 rounded-lg space-y-3">
            <h3 className="text-lg font-semibold flex items-center">
              <Star className="h-5 w-5 text-yellow-500 mr-2" />
              Why Buy More Credits?
            </h3>
            <ul className="space-y-2">
              <li className="flex items-start">
                <Zap className="h-5 w-5 text-blue-500 mr-2 mt-1 flex-shrink-0" />
                <span>Unlock unlimited potential for your web designs</span>
              </li>
              <li className="flex items-start">
                <TrendingUp className="h-5 w-5 text-green-500 mr-2 mt-1 flex-shrink-0" />
                <span>Iterate and improve your designs with AI assistance</span>
              </li>
              <li className="flex items-start">
                <Star className="h-5 w-5 text-purple-500 mr-2 mt-1 flex-shrink-0" />
                <span>Stay ahead with cutting-edge AI-powered features</span>
              </li>
            </ul>
          </div>
        </CardContent>
        <CardFooter>
          <Button
            onClick={handleBuyCredits}
            className="w-full py-6 text-lg bg-gradient-to-r from-blue-500 to-purple-600 hover:from-blue-600 hover:to-purple-700 transition-all duration-300 transform hover:scale-105"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <Loader className="mr-2 h-5 w-5 animate-spin" />
                Processing...
              </>
            ) : (
              <>
                <CreditCard className="mr-2 h-5 w-5" /> Buy More Credits
              </>
            )}
          </Button>
        </CardFooter>
      </Card>
    </div>
  );
};

export default Profile;
