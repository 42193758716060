import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { motion, AnimatePresence } from "framer-motion";
import { Button } from "./ui/button";
import { Dialog, DialogContent } from "./ui/dialog";
import StyleSettings from "./StyleSettings";
import SectionRenderer from "./SectionRenderer";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "./ui/tooltip";

import ToolbarComponent from "./ToolbarComponent";
import { Save } from "lucide-react";
import { useGlobal } from "../contexts/GlobalContext";
import { useAuth } from "../contexts/AuthContext";

const WebsiteBuilder = ({ initialData, onSave }) => {
  const [sections, setSections] = useState(initialData.sections || []);
  const sectionsRef = useRef([]);

  const {
    globalStyle,
    setGlobalStyle,
    websiteContext,
    setWebsiteContext,
    credits,
    setCredits,
    addNotification,
    isLoading,
    setLoading,
  } = useGlobal();
  const { user } = useAuth();
  const [projectType] = useState(initialData.projectType || "website");
  const [isStyleDialogOpen, setIsStyleDialogOpen] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState(false);
  const [isPreviewMode, setIsPreviewMode] = useState(false);
  const [editingElement, setEditingElement] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [contentEdited, setContentEdited] = useState(false);
  const bottomRef = useRef(null);

  const pulseAnimation = {
    scale: [1, 1.05, 1],
    opacity: [0.7, 0.9, 0.7],
    transition: {
      duration: 2,
      repeat: Infinity,
      ease: "easeInOut",
    },
  };
  const navigate = useNavigate();

  const togglePreviewMode = useCallback(() => {
    setIsPreviewMode((prev) => !prev);
  }, []);

  // [] - means that it runs only on mounting
  useEffect(() => {
    if (initialData.globalStyle) {
      setGlobalStyle(initialData.globalStyle);
    }
    if (initialData.websiteContext) {
      setWebsiteContext(initialData.websiteContext);
    }
  }, []);

  const handleUpdateHtml = (sectionId, newHtml) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId ? { ...section, html: newHtml } : section
      )
    );
    setHasUnsavedChanges(true);
  };

  const onDragEnd = useCallback(
    (result) => {
      if (!result.destination) return;
      const newSections = Array.from(sections);
      const [reorderedSection] = newSections.splice(result.source.index, 1);
      newSections.splice(result.destination.index, 0, reorderedSection);
      setSections(newSections);
      setHasUnsavedChanges(true);
    },
    [sections]
  );

  const handleSave = useCallback(async () => {
    setLoading(true);
    try {
      await onSave({
        title: websiteContext,
        sections: sections,
        globalStyle: globalStyle,
      });
      setHasUnsavedChanges(false);
      addNotification({
        type: "success",
        message: "Website saved successfully!",
      });
    } catch (error) {
      console.error("Error saving website:", error);
      addNotification({
        type: "error",
        message: "Failed to save website. Please try again.",
      });
    } finally {
      setLoading(false);
    }
  }, [
    sections,
    globalStyle,
    websiteContext,
    onSave,
    addNotification,
    setLoading,
  ]);

  const exportHTML = useCallback(() => {
    const sectionElements = document.querySelectorAll("[data-export-version]");
    let sectionsHTML = "";
    sectionElements.forEach((sectionElement) => {
      const sectionClone = sectionElement.cloneNode(true);
      sectionClone.removeAttribute("data-export-version");
      sectionClone
        .querySelectorAll('[data-editor-only="true"]')
        .forEach((el) => el.remove());
      sectionsHTML += sectionClone.outerHTML;
    });

    const fullHTML = `
      <!DOCTYPE html>
      <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <script src="https://cdn.tailwindcss.com"></script>
        <title>${websiteContext}</title>
        <style>
          body {
            font-family: ${globalStyle?.fonts?.primary || "Arial, sans-serif"};
            color: ${globalStyle?.colors?.text || "#000000"};
            background-color: ${globalStyle?.colors?.background || "#ffffff"};
          }
        </style>
      </head>
      <body>
        ${sectionsHTML}
      </body>
      </html>
    `;

    const blob = new Blob([fullHTML], { type: "text/html" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "exported_website.html";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);

    addNotification({
      type: "success",
      message: "Website exported successfully!",
    });
  }, [globalStyle, websiteContext, addNotification]);
  const openPreview = useCallback(() => {
    // Assuming you have a pageId. If not, you'll need to generate one or use some unique identifier
    const pageId = initialData.pageId;
    window.open(`/preview/${pageId}`, "_blank");
  }, [initialData.pageId]);

  const addSection = useCallback(() => {
    console.log("Adding new section");
    const newSection = {
      id: Date.now().toString(),
      description: "",
      html: "",
      showAI: true,
    };
    setSections((prevSections) => {
      console.log("Previous sections:", prevSections);
      const newSections = [...prevSections, newSection];
      console.log("New sections:", newSections);
      return newSections;
    });
    setHasUnsavedChanges(true);
    addNotification({ type: "success", message: "New section added." });

    // Scroll to the bottom after adding a new section
    setTimeout(() => {
      bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }, [addNotification, setHasUnsavedChanges]);

  const onAcceptSection = useCallback((sectionId, newHtml) => {
    setSections((prevSections) =>
      prevSections.map((section) =>
        section.id === sectionId ? { ...section, html: newHtml } : section
      )
    );
    setHasUnsavedChanges(true);
  }, []);

  const handleStyleSet = useCallback(
    (newStyle) => {
      setGlobalStyle((prevStyle) => ({
        ...prevStyle,
        ...newStyle,
      }));
      setWebsiteContext(newStyle.websiteContext || websiteContext);
    },
    [websiteContext, setGlobalStyle, setWebsiteContext]
  );

  const generateSection = useCallback(
    async (sectionId) => {
      if (credits < 5) {
        addNotification({
          type: "error",
          message: "Insufficient credits. Please add more credits to continue.",
        });
        return;
      }
      setLoading(true);
      const section = sections.find((s) => s.id === sectionId);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/generate-section`,
          {
            sectionType: section.type,
            globalStyle: globalStyle,
            websiteContext: websiteContext,
          },
          { headers: { "User-ID": user.id } }
        );

        setSections((prevSections) =>
          prevSections.map((s) =>
            s.id === sectionId
              ? {
                  ...s,
                  html: response.data.html,
                  summary: response.data.summary,
                  showAI: false,
                }
              : s
          )
        );

        setCredits(response.data.remainingCredits);
        setHasUnsavedChanges(true);
        addNotification({
          type: "success",
          message: "Section generated successfully.",
        });
      } catch (error) {
        console.error("Error generating section:", error);
        addNotification({
          type: "error",
          message: "Failed to generate section.",
        });
      } finally {
        setLoading(false);
      }
    },
    [
      sections,
      globalStyle,
      websiteContext,
      user.id,
      credits,
      setCredits,
      addNotification,
      setLoading,
    ]
  );

  const handleSaveSection = useCallback(
    async (sectionId, newHtml) => {
      setLoading(true);
      try {
        await onSave({
          sections: sections.map((section) =>
            section.id === sectionId ? { ...section, html: newHtml } : section
          ),
          globalStyle: globalStyle,
        });
        addNotification({
          type: "success",
          message: "Section saved successfully!",
        });
      } catch (error) {
        console.error("Error saving section:", error);
        addNotification({
          type: "error",
          message: "Failed to save section. Please try again.",
        });
      } finally {
        setLoading(false);
      }
    },
    [sections, globalStyle, onSave, addNotification, setLoading]
  );

  const improveSection = useCallback(
    async (sectionId, improvementDescription) => {
      if (credits < 3) {
        addNotification({
          type: "error",
          message: "Insufficient credits. Please add more credits to continue.",
        });
        return;
      }

      setLoading(true);
      const section = sections.find((s) => s.id === sectionId);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_API_SERVER_URL}/improve-section`,
          {
            description: improvementDescription,
            previousHtml: section.html,
            globalStyle: globalStyle,
          },
          { headers: { "User-ID": user.id } }
        );

        setCredits(response.data.remainingCredits);
        setHasUnsavedChanges(true);
        addNotification({
          type: "success",
          message: "Section improved successfully.",
        });
        return response.data.html;
      } catch (error) {
        console.error("Error improving section:", error);
        addNotification({
          type: "error",
          message: "Failed to improve section.",
        });
      } finally {
        setLoading(false);
      }
    },
    [
      sections,
      globalStyle,
      user.id,
      credits,
      setCredits,
      addNotification,
      setLoading,
    ]
  );

  const sectionRenderers = useMemo(
    () =>
      sections.map((section, index) => (
        <Draggable key={section.id} draggableId={section.id} index={index}>
          {(provided) => (
            <motion.div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -50 }}
              transition={{ duration: 0.3 }}
            >
              <SectionRenderer
                section={section}
                index={index}
                onGenerateSection={generateSection}
                onImproveSection={improveSection}
                onAcceptSection={onAcceptSection}
                onUpdateHtml={(sectionId, newHtml) => {
                  setSections((prevSections) =>
                    prevSections.map((s) =>
                      s.id === sectionId ? { ...s, html: newHtml } : s
                    )
                  );
                  setHasUnsavedChanges(true);
                }}
                loading={isLoading}
                onMoveUp={(index) => {
                  if (index > 0) {
                    setSections((prevSections) => {
                      const newSections = [...prevSections];
                      [newSections[index - 1], newSections[index]] = [
                        newSections[index],
                        newSections[index - 1],
                      ];
                      return newSections;
                    });
                    setHasUnsavedChanges(true);
                  }
                }}
                onMoveDown={(index) => {
                  if (index < sections.length - 1) {
                    setSections((prevSections) => {
                      const newSections = [...prevSections];
                      [newSections[index], newSections[index + 1]] = [
                        newSections[index + 1],
                        newSections[index],
                      ];
                      return newSections;
                    });
                    setHasUnsavedChanges(true);
                  }
                }}
                onDuplicate={(index) => {
                  setSections((prevSections) => {
                    const newSections = [...prevSections];
                    const newSection = {
                      ...newSections[index],
                      id: Date.now().toString(),
                    };
                    newSections.splice(index + 1, 0, newSection);
                    return newSections;
                  });
                  setHasUnsavedChanges(true);
                }}
                onRemove={(sectionId) => {
                  setSections((prevSections) =>
                    prevSections.filter((s) => s.id !== sectionId)
                  );
                  setHasUnsavedChanges(true);
                }}
                isFirstSection={index === 0}
                isLastSection={index === sections.length - 1}
                projectType={projectType}
                globalStyle={globalStyle}
              />
            </motion.div>
          )}
        </Draggable>
      )),
    [
      sections,
      generateSection,
      improveSection,
      onAcceptSection,
      isLoading,
      projectType,
      globalStyle,
    ]
  );

  return (
    <main className="container mx-auto px-4 py-8 pt-16 max-w-[95%] relative">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="sections">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              <AnimatePresence>
                {sections.map((section, index) => (
                  <Draggable
                    key={section.id}
                    draggableId={section.id}
                    index={index}
                  >
                    {(provided) => (
                      <motion.div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        initial={{ opacity: 0, y: 50 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -50 }}
                        transition={{ duration: 0.3 }}
                      >
                        <SectionRenderer
                          section={section}
                          index={index}
                          onGenerateSection={generateSection}
                          onImproveSection={improveSection}
                          onUpdateHtml={handleUpdateHtml}
                          onAcceptSection={onAcceptSection}
                          onSaveSection={handleSaveSection}
                          loading={isLoading}
                          onMoveUp={(index) => {
                            if (index > 0) {
                              setSections((prevSections) => {
                                const newSections = [...prevSections];
                                [newSections[index - 1], newSections[index]] = [
                                  newSections[index],
                                  newSections[index - 1],
                                ];
                                return newSections;
                              });
                              setHasUnsavedChanges(true);
                            }
                          }}
                          onMoveDown={(index) => {
                            if (index < sections.length - 1) {
                              setSections((prevSections) => {
                                const newSections = [...prevSections];
                                [newSections[index], newSections[index + 1]] = [
                                  newSections[index + 1],
                                  newSections[index],
                                ];
                                return newSections;
                              });
                              setHasUnsavedChanges(true);
                            }
                          }}
                          onDuplicate={(index) => {
                            setSections((prevSections) => {
                              const newSections = [...prevSections];
                              const newSection = {
                                ...newSections[index],
                                id: Date.now().toString(),
                              };
                              newSections.splice(index + 1, 0, newSection);
                              return newSections;
                            });
                            setHasUnsavedChanges(true);
                          }}
                          onRemove={(sectionId) => {
                            setSections((prevSections) =>
                              prevSections.filter((s) => s.id !== sectionId)
                            );
                            setHasUnsavedChanges(true);
                          }}
                          isFirstSection={index === 0}
                          isLastSection={index === sections.length - 1}
                          projectType={projectType}
                          globalStyle={globalStyle}
                        />
                      </motion.div>
                    )}
                  </Draggable>
                ))}
              </AnimatePresence>
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ToolbarComponent
        showMoreActions={showMoreActions}
        setShowMoreActions={setShowMoreActions}
        openStyleDialog={() => setIsStyleDialogOpen(true)}
        addSection={addSection}
        exportHTML={exportHTML}
        projectType={projectType}
        openPreview={openPreview}
      />
      <Dialog open={isStyleDialogOpen} onOpenChange={setIsStyleDialogOpen}>
        <DialogContent className="max-w-3xl p-0">
          <StyleSettings
            onStyleSet={handleStyleSet}
            initialStyle={globalStyle}
            initialContext={websiteContext}
            userId={user.id}
          />
        </DialogContent>
      </Dialog>
      <AnimatePresence>
        <motion.div
          className="fixed bottom-8 left-0 right-0 flex justify-center z-50"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="relative">
                  {hasUnsavedChanges && (
                    <motion.div
                      className="absolute -inset-0.5 bg-rose-300 rounded-full blur-sm"
                      animate={pulseAnimation}
                    />
                  )}
                  <Button
                    onClick={handleSave}
                    className="relative bg-blue-500 hover:bg-blue-600 text-white font-semibold py-3 px-6 rounded-full shadow-lg flex items-center space-x-2 transition-all duration-300"
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Saving...</span>
                      </>
                    ) : (
                      <>
                        <Save className="w-5 h-5" />
                        <span>Save changes</span>
                      </>
                    )}
                  </Button>
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <p>Save your changes</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </motion.div>
      </AnimatePresence>
      <div ref={bottomRef} /> {/* Ref for scrolling */}
    </main>
  );
};

export default React.memo(WebsiteBuilder);
