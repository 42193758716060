import React, { useEffect, useRef } from "react";
import DOMPurify from "dompurify";

const Section = ({
  html,
  onUpdateHtml,
  id,
  globalStyle,
  sectionId,
  isFirstSection,
  isLastSection,
  isEditing,
}) => {
  const sectionRef = useRef(null);

  useEffect(() => {
    if (sectionRef.current) {
      disableButtons();
    }
  }, [html]);

  const disableButtons = () => {
    const content = sectionRef.current;
    if (!content) return;

    content.querySelectorAll("button, a").forEach((button) => {
      button.addEventListener("click", (e) => {
        e.preventDefault();
        e.stopPropagation();
      });
    });
  };

  const handleContentChange = () => {
    if (sectionRef.current && isEditing) {
      onUpdateHtml(sectionRef.current.innerHTML);
    }
  };

  return (
    <div className="section-wrapper overflow-hidden w-full h-full">
      <div
        ref={sectionRef}
        className="section-content w-full"
        style={{
          fontFamily: globalStyle?.fonts?.primary || "Arial, sans-serif",
          color: globalStyle?.colors?.text || "#000000",
          backgroundColor: globalStyle?.colors?.background || "#ffffff",
        }}
        contentEditable={isEditing}
        onInput={handleContentChange}
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(html, {
            ADD_TAGS: ["script"],
            ADD_ATTR: ["onclick"],
          }),
        }}
      />
      <style>{`
        @import url('https://cdn.jsdelivr.net/npm/tailwindcss@2.2.19/dist/tailwind.min.css');
        .edit-icon { display: none; }

      `}</style>
    </div>
  );
};

export default Section;
