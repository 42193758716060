import React from "react";
import { Button } from "./ui/button";
import { motion, AnimatePresence } from "framer-motion";
import { Plus, Palette, Download, X, MoreVertical, Eye } from "lucide-react";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";
import HelpButton from "./HelpButton";

const ToolbarComponent = ({
  showMoreActions,
  setShowMoreActions,
  openStyleDialog,
  exportHTML,
  addSection,
  projectType,
  openPreview,
}) => {
  return (
    <div className="fixed bottom-8 right-8 flex flex-col space-y-4 z-[60]">
      <AnimatePresence>
        {showMoreActions && (
          <>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={openStyleDialog}
                    className="rounded-full w-14 h-14 shadow-lg bg-purple-700/80 hover:bg-purple-600 transition-colors duration-200"
                  >
                    <Palette className="h-8 w-8" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Edit Global Styles</p>
                </TooltipContent>
              </Tooltip>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={exportHTML}
                    className="rounded-full w-14 h-14 shadow-lg bg-yellow-700/80 hover:bg-yellow-600 transition-colors duration-200"
                  >
                    <Download className="h-8 w-8" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Export HTML</p>
                </TooltipContent>
              </Tooltip>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    onClick={openPreview}
                    className="rounded-full w-14 h-14 shadow-lg bg-green-700/80 hover:bg-green-600 transition-colors duration-200"
                  >
                    <Eye className="h-8 w-8" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Preview Website</p>
                </TooltipContent>
              </Tooltip>
            </motion.div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              <HelpButton />
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            onClick={() => setShowMoreActions(!showMoreActions)}
            className="rounded-full w-14 h-14 shadow-lg bg-gray-700/80 hover:bg-gray-600 transition-colors duration-200"
          >
            {showMoreActions ? (
              <X className="h-8 w-8" />
            ) : (
              <MoreVertical className="h-8 w-8" />
            )}
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{showMoreActions ? "Hide Options" : "Show More Options"}</p>
        </TooltipContent>
      </Tooltip>

      {projectType === "website" && (
        <Tooltip>
          <TooltipTrigger asChild>
            <div className="w-14 h-14">
              {" "}
              {/* Wrapper div to extend clickable area */}
              <Button
                onClick={addSection}
                className="rounded-full w-full h-full shadow-lg bg-blue-700/80 hover:bg-blue-600 transition-colors duration-200 flex items-center justify-center"
              >
                <Plus className="h-8 w-8" />
              </Button>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <p>Add New Section</p>
          </TooltipContent>
        </Tooltip>
      )}
    </div>
  );
};

export default ToolbarComponent;
